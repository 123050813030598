import {
	Directive,
	EventEmitter,
	inject,
	Input,
	NgZone,
	OnDestroy,
	Output,
} from '@angular/core';
import {
	BehaviorSubject,
	of,
	Subscription,
	filter,
	map,
	switchMap,
} from 'rxjs';
import { CoRiveCanvasDirective } from './canvas.directive';
import { CoRiveService } from './rive.service';
import type {
	Artboard,
	LinearAnimationInstance,
	LinearAnimation,
} from '@rive-app/webgl-advanced';
import { isNonNullish } from '@consensus/co/util-types';

interface RiveAnimationState {
	speed: number;
	playing: boolean;
	/** Weight of this animation over another */
	mix: number;
}

function getRiveAnimationState(
	state: Partial<RiveAnimationState> = {}
): RiveAnimationState {
	return {
		speed: 1,
		playing: false,
		mix: 1,
		...state,
	};
}

function assertAnimation(
	animation: LinearAnimation,
	artboard: Artboard,
	name: string | number
) {
	if (animation) {
		return;
	}
	const artboardName = artboard.name ?? 'Default';
	const count = artboard.animationCount();
	if (typeof name === 'number') {
		throw new Error(
			`Provided index "${name}" for the animation of artboard "${artboardName}" is not available. Animation count is: ${count}`
		);
	} else {
		const names: string[] = [];
		for (let i = 0; i < count; i++) {
			names.push(artboard.animationByIndex(i).name);
		}
		throw new Error(
			`Provided name "${name}" for the animation of artboard "${artboardName}" is not available. Availables names are: ${JSON.stringify(
				names
			)}`
		);
	}
}

/**
 * Rive directive representing a LinearAnimation instance.
 * Use this class to advance and control a particular animation in the render loop (i.e speed, scrub, mix, etc.).
 * This can be done in cases where the user navigates away from the page with this animation, the canvas is unmounted, etc.
 */
@Directive({
	selector: 'co-rive-animation, [coRiveAnimation]',
	standalone: true,
})
export class CoRiveLinearAnimationDirective implements OnDestroy {
	readonly #zone = inject(NgZone);
	readonly #canvas = inject(CoRiveCanvasDirective);
	readonly #service = inject(CoRiveService);
	#sub?: Subscription;
	#instance?: LinearAnimationInstance;
	readonly distance = new BehaviorSubject<number | null>(null);
	readonly state = new BehaviorSubject<RiveAnimationState>(
		getRiveAnimationState()
	);

	/**
	 * Name of the rive animation in the current Artboard.
	 * Either use name Input ({@link name}) or index Input ({@link index}) to
	 * select an animation.
	 */
	@Input()
	set name(name: string | undefined | null) {
		if (typeof name !== 'string') {
			return;
		}
		this.#zone.runOutsideAngular(() => {
			this.#register(name);
		});
	}

	/**
	 * Index of the rive animation in the current Artboard.
	 * Either use index Input ({@link index}) or name Input ({@link name}) to
	 * select an animation.
	 */
	@Input()
	set index(value: number | string | undefined | null) {
		const index = typeof value === 'string' ? parseInt(value) : value;
		if (typeof index !== 'number') {
			return;
		}
		this.#zone.runOutsideAngular(() => {
			this.#register(index);
		});
	}

	/** The mix of this animation in the current artboard */
	@Input()
	set mix(value: number | string | undefined | null) {
		const mix = typeof value === 'string' ? parseFloat(value) : value;
		if (mix && mix >= 0 && mix <= 1) {
			this.#update({ mix });
		}
	}
	get mix() {
		return this.state.getValue().mix;
	}

	/** Multiplicator for the speed of the animation */
	@Input()
	set speed(value: number | string | undefined | null) {
		const speed = typeof value === 'string' ? parseFloat(value) : value;
		if (typeof speed === 'number') {
			this.#update({ speed });
		}
	}
	get speed() {
		return this.state.getValue().speed;
	}

	/** If true, this animation is playing */
	@Input() set play(playing: boolean | '' | undefined | null) {
		if (playing === true || playing === '') {
			this.#update({ playing: true });
		} else if (playing === false) {
			this.#update({ playing: false });
		}
	}
	get play() {
		return this.state.getValue().playing;
	}

	/** Emit when the LinearAnimation has been instantiated */
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() load = new EventEmitter<LinearAnimationInstance>();

	ngOnDestroy() {
		this.#sub?.unsubscribe();
		setTimeout(() => this.#instance?.delete(), 100);
	}

	#update(state: Partial<RiveAnimationState>) {
		const next = getRiveAnimationState({ ...this.state.getValue(), ...state });
		this.state.next(next);
	}

	#getFrame(state: RiveAnimationState) {
		if (state.playing && this.#service.frame) {
			return this.#service.frame.pipe(map(time => [state, time] as const));
		} else {
			return of(null);
		}
	}

	#initAnimation(name: string | number) {
		if (!this.#canvas.rive) {
			throw new Error('Could not load animation #instance before rive');
		}
		if (!this.#canvas.artboard) {
			throw new Error('Could not load animation #instance before artboard');
		}
		const ref =
			typeof name === 'string'
				? this.#canvas.artboard.animationByName(name)
				: this.#canvas.artboard.animationByIndex(name);

		assertAnimation(ref, this.#canvas.artboard, name);

		this.#instance = new this.#canvas.rive.LinearAnimationInstance(
			ref,
			this.#canvas.artboard
		);
		this.load.emit(this.#instance);
	}

	#register(name: string | number) {
		// Stop subscribing to previous animation if any
		this.#sub?.unsubscribe();

		// Update on frame change if playing
		const onFrameChange = this.state.pipe(
			switchMap(state => this.#getFrame(state)),
			filter(isNonNullish),
			map(([state, time]) => (time / 1000) * state.speed)
		);

		// Wait for canvas & animation to be loaded
		this.#sub = this.#canvas
			.onReady()
			.pipe(
				map(() => this.#initAnimation(name)),
				switchMap(() => onFrameChange)
			)
			.subscribe(delta => this.#applyChange(delta));
	}

	#applyChange(delta: number) {
		if (!this.#instance) {
			throw new Error('Could not load animation #instance before running it');
		}
		this.#canvas.draw(this.#instance, delta, this.state.getValue().mix);
	}
}
