import { OpenUrlEvent, RiveEvent } from '@rive-app/webgl-advanced';

/**
 * These values are default Rive Event values, used to differentiate the
 * different types of Events defined by the creator of the Rive file.
 */
export enum CoRiveEventType {
	General = 128,
	OpenUrl = 131,
}

/**
 * These values are default Rive Input values, used to differentiate the
 * different types of inputs defined by the creator of the Rive file.
 */
export enum CoRiveStateMachineInputType {
	Number = 56,
	Trigger = 58,
	Boolean = 59,
}

/**
 * Contents of a state machine input
 */
export interface CoRiveStateMachineInputContents {
	name: string;
	type: CoRiveStateMachineInputType;
	initialValue?: boolean | number;
}

/**
 * Contents of a state machine
 */
export interface CoRiveStateMachineContent {
	name: string;
	inputs: CoRiveStateMachineInputContents[];
}

export type CoReportedRiveEvent = OpenUrlEvent | RiveEvent;

/**
 * Contents of an artboard
 */
export interface CoArtboardContent {
	animations: string[];
	events: CoReportedRiveEvent[];
	stateMachines: CoRiveStateMachineContent[];
	name: string;
}

/**
 * Contents of a Rive file
 */
export interface CoRiveFileContent {
	artboards: CoArtboardContent[];
}

/** Fit determines how the Rive content will be fitted to the view */
export type CoRiveCanvasFit =
	| 'cover'
	| 'contain'
	| 'fill'
	| 'fitWidth'
	| 'fitHeight'
	| 'none'
	| 'scaleDown';

export type CoRiveCanvasAlignment =
	| 'center'
	| 'topLeft'
	| 'topCenter'
	| 'topRight'
	| 'centerLeft'
	| 'centerRight'
	| 'bottomLeft'
	| 'bottomCenter'
	| 'bottomRight';
